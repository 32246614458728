import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
var SmsPageCase016Component = /** @class */ (function (_super) {
    tslib_1.__extends(SmsPageCase016Component, _super);
    function SmsPageCase016Component(route, router) {
        return _super.call(this, route, router, 'faq-case016') || this;
    }
    SmsPageCase016Component.prototype.onPinEvent = function (pin) {
        _super.prototype.onPinEvent.call(this, pin);
    };
    return SmsPageCase016Component;
}(SmsPageCaseComponent));
export { SmsPageCase016Component };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
var Level1FaqComponent = /** @class */ (function () {
    function Level1FaqComponent(route, router, faqService, errorsService, location, pinsService) {
        this.route = route;
        this.router = router;
        this.faqService = faqService;
        this.errorsService = errorsService;
        this.location = location;
        this.pinsService = pinsService;
        this.a = null;
        this.d = null;
        this.p = null;
        this.errorStatus = null;
        this.errorMessage = null;
        this.isLoading = false;
        this.faqTree = null;
        this.level1Faq = null;
        this.faArrowRight = faArrowRight;
        this.pin = null;
    }
    Level1FaqComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(' [Level1FaqComponent] + p --> ', p);
                        this.a = p.a;
                        this.d = p.d;
                        this.p = p.p;
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.d (problemCode) --> ', this.d);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.faqService.getFaqFlussoGenerico(this.p, this.a)];
                    case 2: return [4 /*yield*/, (_b.sent()).toPromise()];
                    case 3:
                        _a.faqTree = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _b.sent();
                        console.log(err_1);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 5:
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
                        // this.level1Faq = Object.keys(this.faqTree);
                        // for (let i = 0; i < this.level1Faq.length; i++) {
                        //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
                        // }
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log('Error message --->', error);
            _this.router.navigate(['error']);
            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
            // this.errorStatus = errObj.statusHeader;
            // this.errorMessage = errObj.statusMessage;
        });
    };
    Level1FaqComponent.prototype.onPinEvent = function (pin) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(' + onPinEvent() --> pin --> ', pin);
                        this.pin = pin;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.faqService.getFaqFlussoGenerico(this.p, this.a)];
                    case 2: return [4 /*yield*/, (_b.sent()).toPromise()];
                    case 3:
                        _a.faqTree = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _b.sent();
                        console.log(err_2);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 5:
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
                        // this.level1Faq = Object.keys(this.faqTree);
                        // for (let i = 0; i < this.level1Faq.length; i++) {
                        //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
                        // }
                        console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);
                        return [2 /*return*/];
                }
            });
        });
    };
    Level1FaqComponent.prototype.onErrorEvent = function () {
        //this.isError = true;
    };
    Level1FaqComponent.prototype.onClickLevel1Faq = function (level1Faq) {
        console.log(' [Level1FaqComponent] + onClickLevel1Faq() --> level1Faq --> ', level1Faq);
        this.router.navigate(['level2-faq'], {
            queryParams: { level1Faq: level1Faq },
            queryParamsHandling: 'merge'
        });
    };
    Level1FaqComponent.prototype.onClickAltro = function () {
        console.log(' + onClickAltro()');
        this.router.navigate(['level2-faq'], {
            queryParamsHandling: 'preserve'
        });
    };
    Level1FaqComponent.prototype.onCloseAlert = function () {
        console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);
        this.errorStatus = null;
        this.errorMessage = null;
    };
    return Level1FaqComponent;
}());
export { Level1FaqComponent };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FaqCaseComponent } from 'src/app/core/faq-case-component';
var FaqCase016Component = /** @class */ (function (_super) {
    tslib_1.__extends(FaqCase016Component, _super);
    function FaqCase016Component(route, router, faqService, pinsService, errorsService) {
        return _super.call(this, route, router, faqService, pinsService, errorsService) || this;
    }
    FaqCase016Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    return FaqCase016Component;
}(FaqCaseComponent));
export { FaqCase016Component };

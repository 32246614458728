import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KpiLogEvent = /** @class */ (function () {
    function KpiLogEvent(actionId, code, onceXSession) {
        this.actionId = actionId;
        this.code = code;
        this.onceXSession = onceXSession;
        this.sent = 0;
    }
    KpiLogEvent.prototype.getActionId = function () {
        return this.actionId;
    };
    KpiLogEvent.prototype.getCode = function () {
        return this.code;
    };
    KpiLogEvent.prototype.couldSend = function () {
        return this.onceXSession ? this.sent == 0 : true;
    };
    KpiLogEvent.prototype.markSent = function () {
        this.sent++;
    };
    KpiLogEvent.SITE_ACCESS_EVENT = new KpiLogEvent('genericflow_access', '00', false);
    KpiLogEvent.CTRL_PANEL_GENERIC = new KpiLogEvent('ctrlpanel_generic_access', '09', true);
    KpiLogEvent.CTRL_PANEL_SMS = new KpiLogEvent('ctrlpanel_sms_access', '10', true);
    return KpiLogEvent;
}());
export { KpiLogEvent };
var KpiLogsService = /** @class */ (function () {
    function KpiLogsService(http) {
        this.http = http;
        this.base_url = environment.base_url;
    }
    KpiLogsService.prototype.registerKPILogs = function (event, args) {
        if (args === void 0) { args = null; }
        console.log('KpiLogsService.registerKPILogs: %o', event);
        if (event.couldSend()) {
            if (args) {
                args['eventTimestamp'] = new Date();
                args['code'] = event.getCode();
            }
            else {
                args = {};
            }
            this.http.post(this.base_url + '/kpi-logs/' + event.getActionId(), args).subscribe(function (res) {
                console.log('KpiLogsService.registerKPILogs: sent => %o', res);
                event.markSent();
            }, function (err) {
                console.log('KpiLogsService.registerKPILogs: error => %o', err);
            });
        }
        else {
            console.log('KpiLogsService.registerKPILogs: event %s already sent', event.getActionId());
        }
    };
    KpiLogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KpiLogsService_Factory() { return new KpiLogsService(i0.ɵɵinject(i1.HttpClient)); }, token: KpiLogsService, providedIn: "root" });
    return KpiLogsService;
}());
export { KpiLogsService };

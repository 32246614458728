import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
var SmsPageCase017Component = /** @class */ (function (_super) {
    tslib_1.__extends(SmsPageCase017Component, _super);
    function SmsPageCase017Component(route, router) {
        return _super.call(this, route, router, 'faq-case017') || this;
    }
    SmsPageCase017Component.prototype.onPinEvent = function (pin) {
        _super.prototype.onPinEvent.call(this, pin);
    };
    return SmsPageCase017Component;
}(SmsPageCaseComponent));
export { SmsPageCase017Component };

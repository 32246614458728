import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FaqService = /** @class */ (function () {
    function FaqService(http) {
        this.http = http;
        this.base_url = environment.base_url;
    }
    FaqService.prototype.getFaqFlussoGenerico = function (pin, vehiclePlate) {
        return this.http.get(this.base_url + "/genericflow/faq?pin=" + pin + "&vehiclePlate=" + vehiclePlate);
    };
    FaqService.prototype.getFaqFlussoSMS = function (anomalyCode, imeiCode) {
        if (anomalyCode === void 0) { anomalyCode = '004' || '009'; }
        return this.http.get(this.base_url + "/smsflow/faq?anomalyCode=" + anomalyCode + "&imeiCode=" + imeiCode);
    };
    FaqService.prototype.getFaqFlussoGenerico_old = function (pin) {
        return this.http.get(this.base_url + "/genericflow/faq?pin=" + pin.pin + "&vehiclePlate=" + pin.vehiclePlate);
    };
    FaqService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FaqService_Factory() { return new FaqService(i0.ɵɵinject(i1.HttpClient)); }, token: FaqService, providedIn: "root" });
    return FaqService;
}());
export { FaqService };

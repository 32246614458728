import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faqSms009 } from 'src/app/models/faq-sms.model';
var FaqCase009Component = /** @class */ (function () {
    function FaqCase009Component(route, router, faqService, errorsService, pinsService) {
        this.route = route;
        this.router = router;
        this.faqService = faqService;
        this.errorsService = errorsService;
        this.pinsService = pinsService;
        this.a = null;
        this.note = null;
        this.d = null;
        this.p = null;
        this.b = null;
        this.c = null;
        this.errorStatus = null;
        this.errorMessage = null;
        this.isLoading = false;
        this.pin = null;
        this.faqTree = null;
        this.level1Faq = null;
        this.faCheckSquare = faCheckSquare;
        this.faSquare = faSquare;
        this.faChevronRight = faChevronRight;
        this.exitID = null;
    }
    FaqCase009Component.prototype.ngOnInit = function () {
        var _this = this;
        this.faqTree = new faqSms009();
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var faq, _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(' + p --> ', p);
                        this.a = p.a;
                        this.b = p.b; //company 
                        this.d = p.d;
                        this.p = p.p;
                        this.c = p.c;
                        console.log(' [FaqCase009Component] + ngOnInit() --> this.a (targa) --> ', this.a);
                        console.log(' [FaqCase009Component] + ngOnInit() --> this.b (company) --> ', this.b);
                        console.log(' [FaqCase009Component] + ngOnInit() --> this.d (problemCode) --> ', this.d);
                        console.log(' [FaqCase009Component] + ngOnInit() --> this.c (imeiCode) --> ', this.c);
                        if (!this.a || !this.d) {
                            this.errorStatus = 'Non è possibile continuare la navigazione';
                            this.errorMessage = 'La targa e il problem code sono obbligatori';
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.faqService.getFaqFlussoSMS(this.d, this.c)];
                    case 2: return [4 /*yield*/, (_b.sent()).toPromise()];
                    case 3:
                        faq = _b.sent();
                        this.faqTree.faq = faq;
                        console.log(' + ngOnInit() --> this.faqTree -->', this.faqTree);
                        this.faqTree.faq.forEach(function (faqElement) {
                            faqElement.isVisible = false;
                            faqElement.note = "";
                        });
                        _a = this;
                        return [4 /*yield*/, this.pinsService.pinData(this.a, this.p, 'S')];
                    case 4:
                        _a.pin = _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _b.sent();
                        console.log(err_1);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 6: return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log('Error message ---> ', error);
            _this.router.navigate(['error']);
            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
            // this.errorStatus = errObj.statusHeader;
            // this.errorMessage = errObj.statusMessage;
        });
    };
    FaqCase009Component.prototype.onClickLevel1Faq = function (faq) {
        this.exitID = faq.exit;
        console.log('[FaqCase009Component] + onClickLevel1Faq() --> faq -->', faq);
        console.log(' [FaqCase009Component]+ onClickLevel1Faq() --> faq -->', faq.exit, ' this.exitID ', this.exitID);
        this.level1Faq = !faq.isVisible ? faq.nome : null;
        this.note = faq.note;
        console.log(' [FaqCase009Component] + onClickLevel1Faq() --> this.level1Faq -->', this.level1Faq);
        console.log(' [FaqCase009Component] + onClickLevel1Faq() --> this.note -->', this.note);
        this.faqTree.faq.forEach(function (faqElement) {
            if (faqElement.nome === faq.nome) {
                faqElement.isVisible = !faqElement.isVisible;
            }
            else {
                faqElement.isVisible = false;
            }
            if (!faqElement.isVisible) {
                faqElement.note = "";
            }
        });
    };
    FaqCase009Component.prototype.onClickAltro = function () {
        console.log(' + onClickAltro()');
        this.router.navigate(['level2-faq'], {
            queryParamsHandling: 'preserve'
        });
    };
    FaqCase009Component.prototype.onRegistra = function () {
        var _this = this;
        console.log(' [FaqCase009Component] + onRegistra() --> this.note-->', this.faqTree);
        console.log(' [FaqCase009Component] + onRegistra() --> this.level1Faq --> ', this.level1Faq);
        console.log(' [FaqCase009Component] + onRegistra() --> this.note-->', this.note);
        console.log(' [FaqCase009Component]+ onClickLevel1Faq() --> this.exitID ', this.exitID);
        this.faqTree.faq.forEach(function (faqElement) {
            if (faqElement.isVisible) {
                _this.note = faqElement.note;
            }
        });
        this.clearSessionStorage();
        this.router.navigate(['pin-page'], {
            queryParams: {
                a: this.a,
                b: this.b,
                level1Faq: this.level1Faq,
                note: this.note,
                paramExitID: this.exitID,
                communicationType: this.faqTree.email ? 'si' : 'no',
            },
            queryParamsHandling: 'merge'
        });
    };
    FaqCase009Component.prototype.onCloseAlert = function () {
        console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);
        this.errorStatus = null;
        this.errorMessage = null;
    };
    FaqCase009Component.prototype.clearSessionStorage = function () {
        if (sessionStorage.getItem('PinStorage')) {
            console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
            sessionStorage.removeItem('PinStorage');
        }
    };
    return FaqCase009Component;
}());
export { FaqCase009Component };

import { Routes } from '@angular/router';
// Components
import { HomeComponent } from './components/home/home.component';
import { SmsPageComponent } from './components/sms-page/sms-page.component';
import { Level2FaqComponent } from './components/level2-faq/level2-faq.component';
import { PinPageComponent } from './components/pin-page/pin-page.component';
import { SmsPageCase009Component } from './components/sms-page-case009/sms-page-case009.component';
import { SmsPageCase004Component } from './components/sms-page-case004/sms-page-case004.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SmsPageCase000Component } from './components/sms-page-case000/sms-page-case000.component';
import { Level1FaqComponent } from './components/level1-faq/level1-faq.component';
import { FaqCase004Component } from './components/faq-case004/faq-case004.component';
import { FaqCase009Component } from './components/faq-case009/faq-case009.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { SmsFlowEndComponent } from './components/sms-flow-end/sms-flow-end.component';
import { ContractPageComponent } from './components/contract-page/contract-page.component';
import { SmsPageCase007Component } from './components/sms-page-case007/sms-page-case007.component';
import { SmsPageCase016Component } from './components/sms-page-case016/sms-page-case016.component';
import { SmsPageCase017Component } from './components/sms-page-case017/sms-page-case017.component';
import { FaqCase007Component } from './components/faq-case007/faq-case007.component';
import { FaqCase016Component } from './components/faq-case016/faq-case016.component';
import { FaqCase017Component } from './components/faq-case017/faq-case017.component';
var ɵ0 = { title: '404' };
var routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'sms-page', component: SmsPageComponent },
    { path: 'sms', component: SmsPageComponent },
    { path: 'pin-page', component: PinPageComponent },
    { path: 'sms-page-case000', component: SmsPageCase000Component },
    { path: 'level1-faq', component: Level1FaqComponent },
    { path: 'level2-faq', component: Level2FaqComponent },
    { path: 'sms-page-case004', component: SmsPageCase004Component },
    { path: 'faq-case004', component: FaqCase004Component },
    { path: 'faq-case009', component: FaqCase009Component },
    { path: 'faq-case007', component: FaqCase007Component },
    { path: 'faq-case016', component: FaqCase016Component },
    { path: 'faq-case017', component: FaqCase017Component },
    { path: 'sms-page-case009', component: SmsPageCase009Component },
    { path: 'sms-page-case007', component: SmsPageCase007Component },
    { path: 'sms-page-case016', component: SmsPageCase016Component },
    { path: 'sms-page-case017', component: SmsPageCase017Component },
    { path: 'sms-flow-end', component: SmsFlowEndComponent },
    { path: 'contract-page', component: ContractPageComponent },
    { path: 'error', component: PageErrorComponent },
    { path: '404', component: PageNotFoundComponent, data: ɵ0 },
    { path: '**', redirectTo: '/404' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { KpiLogEvent } from 'src/app/services/kpi-logs.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, kpiLogsService, route) {
        this.router = router;
        this.kpiLogsService = kpiLogsService;
        this.route = route;
        this.errorStatus = null;
        this.errorMessage = null;
        this.browserNotSupportedMsg = 'Questo browser non è supportato, utilizzane uno tra i seguenti: Chrome, Firefox, Microsoft Edge e Safari.';
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.targaForm = new FormGroup({
            targa: new FormControl(null, Validators.required)
        });
        this.checkBrowser();
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                console.log('[HomeComponent] + p --> ', p);
                if (!(typeof (p.ts) != 'undefined'))
                    this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log('[HomeComponent] Error message ---> ', error);
        });
        this.kpiLogsService.registerKPILogs(KpiLogEvent.SITE_ACCESS_EVENT);
    };
    HomeComponent.prototype.onSubmit = function () {
        console.log(' [HomeComponent] + onSubmit() --> this.targaForm --> ', this.targaForm);
        this.clearSessionStorage();
        var a = this.targaForm.value.targa.toUpperCase();
        console.log(' [HomeComponent] + onSubmit() --> targa --> ', a);
        this.router.navigate(['/sms-page'], { queryParams: { a: a, d: '000', ts: new Date().valueOf() } });
    };
    Object.defineProperty(HomeComponent.prototype, "form", {
        get: function () {
            return this.targaForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    HomeComponent.prototype.clearSessionStorage = function () {
        if (sessionStorage.getItem('PinStorage')) {
            console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
            sessionStorage.removeItem('PinStorage');
        }
    };
    HomeComponent.prototype.checkBrowser = function () {
        // Get the user-agent string
        var userAgentString = navigator.userAgent;
        // Detect Chrome
        var chromeAgent = userAgentString.indexOf('Chrome') > -1;
        // Detect Internet Explorer
        var IExplorerAgent = userAgentString.indexOf('MSIE') > -1 ||
            userAgentString.indexOf('rv:') > -1;
        // Detect Firefox
        var firefoxAgent = userAgentString.indexOf('Firefox') > -1;
        // Discard IExplorer since it also matches Firefox
        if ((firefoxAgent) && (IExplorerAgent)) {
            IExplorerAgent = false;
        }
        // Detect Safari
        var safariAgent = userAgentString.indexOf('Safari') > -1;
        // Discard Safari since it also matches Chrome
        if ((chromeAgent) && (safariAgent)) {
            safariAgent = false;
        }
        // Detect Opera
        var operaAgent = userAgentString.indexOf('OP') > -1;
        // Discard Chrome since it also matches Opera
        if ((chromeAgent) && (operaAgent)) {
            chromeAgent = false;
        }
        var edgeAgent = userAgentString.indexOf('Edg') > -1;
        // Discard Chrome since it also matches Edge
        if ((edgeAgent) && (chromeAgent)) {
            chromeAgent = false;
        }
        console.log('isChrome?', chromeAgent);
        console.log('isIExplorer?', IExplorerAgent);
        console.log('isFireFox?', firefoxAgent);
        console.log('isSafari?', safariAgent);
        console.log('isOpera?', operaAgent);
        console.log('isEdge?', edgeAgent);
        if ((!edgeAgent) && (!chromeAgent) && (!firefoxAgent) && (!safariAgent)) {
            alert(this.browserNotSupportedMsg);
        }
    };
    return HomeComponent;
}());
export { HomeComponent };

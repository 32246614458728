import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { Pin } from '../models/pin.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PinsService = /** @class */ (function () {
    function PinsService(http) {
        this.http = http;
        this.base_url = environment.base_url;
    }
    PinsService.prototype.getPinsFlussoGenerico = function (targa, pin) {
        return this.http.get(this.base_url + '/genericflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin);
    };
    PinsService.prototype.getPinsSms = function (targa, pin) {
        return this.http.get(this.base_url + '/smsflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin);
    };
    PinsService.prototype.createPinFlussoGenerico = function (targa, faqData) {
        var inputData = {
            vehiclePlate: targa,
        };
        return this.http.post(this.base_url + '/genericflow/' + 'pins', inputData);
    };
    PinsService.prototype.patchPinFlussoGenerico = function (id, data) {
        return this.http.patch(this.base_url + '/genericflow/' + 'pins/' + id, data);
    };
    PinsService.prototype.getPinsFlussoSMS = function (a, p, d) {
        if (d === void 0) { d = '004' || '009'; }
        return this.http.get(this.base_url + "/smsflow/pins?vehiclePlate=" + a + "&pin=" + p);
    };
    PinsService.prototype.createPinFlussoSMS = function (a, b, c, d) {
        if (b === void 0) { b = "U" || "L" || "A"; }
        if (d === void 0) { d = '004' || '009'; }
        var inputData = {
            vehiclePlate: a,
            companyId: b,
            imeiCode: c,
            anomalyCode: d
        };
        var url = '';
        url = url.concat(this.base_url, '/smsflow/pins');
        return this.http.post(url, inputData);
    };
    PinsService.prototype.patchPinFlussoSms = function (id, data) {
        console.log('[PinsService-> patchPinFlussoSms] --> ', ' id ', id, ' data ', data);
        return this.http.patch(this.base_url + '/smsflow/' + 'pins/' + id, data);
    };
    PinsService.prototype.pinData = function (targa, p, type) {
        if (type === void 0) { type = 'G' || 'S'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pin, pinData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pinData = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(type == 'G')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPinsFlussoGenerico(targa, p).toPromise()];
                    case 2:
                        pinData = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (!(type == 'S')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getPinsFlussoSMS(targa, p).toPromise()];
                    case 4:
                        pinData = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.error(error_1);
                        throw error_1;
                    case 7:
                        console.log(' pinsService.getPinsFlussoGenerico(' + targa + ') --> ', pinData);
                        pin = new Pin(null, targa, new Date(), new Date(), 'M', null, null, 'R', null, null, null, null, null, null, false, new Date());
                        pin.pin = pinData.pin;
                        pin.vehiclePlate = pinData.vehiclePlate;
                        pin.created = pinData.created;
                        pin.expirationDate = pinData.expirationDate;
                        pin.competencePlate = pinData.competencePlate;
                        pin.email = pinData.email;
                        pin.deviceType = pinData.deviceType;
                        pin.codeMessage = pinData.codeMessage;
                        pin.hasEmail = pinData.hasEmail;
                        pin.communicationType = pinData.deviceType;
                        pin.otherInfo = pinData.otherInfo;
                        pin.contractId = pinData.contractId;
                        pin.insurancePolicy = pinData.insurancePolicy;
                        pin.deviceStatus = pinData.deviceStatus;
                        pin.insuranceStartDate = pinData.insuranceStartDate;
                        return [2 /*return*/, pin];
                }
            });
        });
    };
    PinsService.prototype.getContractInfo = function (plate, company) {
        return this.http.get(this.base_url + "/contractInfo?vehiclePlate=" + plate + (company ? "&companyId=" + company : ''));
    };
    PinsService.prototype.getSMSDetails = function (smsId) {
        return this.http.get(this.base_url + "/getSMSDetails?smsLog=" + smsId
        //`http://localhost:3000/api/getSMSDetails?smsLog=${smsId}`
        );
    };
    PinsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PinsService_Factory() { return new PinsService(i0.ɵɵinject(i1.HttpClient)); }, token: PinsService, providedIn: "root" });
    return PinsService;
}());
export { PinsService };

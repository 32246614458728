import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
var FaqCase004Component = /** @class */ (function () {
    function FaqCase004Component(route, router, faqService, pinsService, errorsService) {
        this.route = route;
        this.router = router;
        this.faqService = faqService;
        this.pinsService = pinsService;
        this.errorsService = errorsService;
        this.p = null;
        this.a = null;
        this.b = null;
        this.d = null;
        this.c = null;
        this.note = null;
        this.errorStatus = null;
        this.errorMessage = null;
        this.isLoading = false;
        this.pin = null;
        this.faqTree = null;
        this.level1Faq = null;
        this.currentIndex = 0;
    }
    FaqCase004Component.prototype.ngOnInit = function () {
        var _this = this;
        this.currentIndex = 0;
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b, err_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log(' + p --> ', p);
                        this.a = p.a; //targa
                        this.b = p.b; //company 
                        this.d = p.d; //problemCode
                        this.p = p.p; //pin
                        this.c = p.c; //imeiCode
                        console.log('[FaqCase004Component] + ngOnInit() --> this.a (targa) --> ', this.a);
                        console.log(' [FaqCase009Component] + ngOnInit() --> this.b (company) --> ', this.b);
                        console.log(' [FaqCase004Component] + ngOnInit() --> this.d (problemCode) --> ', this.d);
                        console.log(' [FaqCase004Component] + ngOnInit() --> this.p (pin) --> ', this.p);
                        console.log('[FaqCase004Component] + ngOnInit() --> this.c   --> ', this.c);
                        if (!this.a || !this.d) {
                            this.errorStatus = 'Non è possibile continuare la navigazione';
                            this.errorMessage = 'La targa e il problem code sono obbligatori';
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.faqService.getFaqFlussoSMS(this.d, this.c).toPromise()];
                    case 2:
                        _a.faqTree = (_c.sent());
                        console.log(' [FaqCase004Component] + ngOnInit() --> this.faqTree -->', this.faqTree);
                        this.faqTree.faq.forEach(function (faqElement) {
                            console.log(' [FaqCase004Component] + ngOnInit() --> faq -->', faqElement);
                        });
                        _b = this;
                        return [4 /*yield*/, this.pinsService.pinData(this.a, this.p, 'S')];
                    case 3:
                        _b.pin = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _c.sent();
                        console.log(err_1);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 5:
                        console.log(' + ngOnInit() --> Object Pin -->', this.pin);
                        // this.faqTree.forEach(faqElement => {
                        //   faqElement = null;
                        // });
                        console.log(' [FaqCase004Component] + ngOnInit() --> this.faqTree -->', this.faqTree);
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.log('Error message ---> ', error);
            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
            // this.errorStatus = errObj.statusHeader;
            // this.errorMessage = errObj.statusMessage;
            _this.router.navigate(['error']);
        });
    };
    FaqCase004Component.prototype.onClickOptions = function (faq, optionType) {
        if (optionType == 'yes') {
            if (faq.SI != null && faq.SI.risposta != '') {
                faq.isVisible = true;
                this.note = faq.note;
            }
            else {
                faq.isVisible = false;
            }
        }
        else if (optionType == 'no') {
            if (faq.NO != null && faq.NO.risposta != '') {
                faq.isVisible = true;
                this.note = faq.note;
            }
            else {
                faq.isVisible = false;
            }
        }
        console.log("optionType: ", optionType);
        console.log("faq: ", faq);
    };
    FaqCase004Component.prototype.onRegistra = function (event) {
        console.log('[FaqCase004Component ] onRegistra() --> this.faqTree --> ', this.faqTree);
        console.log("[FaqCase004Component ] onRegistra() -->  this.faqTree.exit_message ", this.faqTree.exit_message);
        console.log("[FaqCase004Component ] onRegistra() -->   this.faqTree.faq ", this.faqTree.faq);
        event.preventDefault();
        this.level1Faq = '';
        var exit_message = '';
        var exit = null;
        var exitID = null;
        for (var i = 0; i < this.faqTree.faq.length; i++) {
            console.log("[FaqCase004Component ] onRegistra() -->   this.faqTree.faq[i].nome  ", this.faqTree.faq[i].nome);
            var risposta = this.faqTree.faq[i].opzioneUtente ? 'sì' : 'no';
            // let note = (this.faqTree[i].note != "" && this.faqTree[i].note != undefined) ? this.faqTree[i].note : '';
            // this.level1Faq += this.faqTree[i].testo + ' --> ' + 'risposta: ' + risposta + ' note: ' + note;
            // let note = (this.faqTree[i].note != "" && this.faqTree[i].note != undefined) ? this.faqTree[i].note : '';
            this.level1Faq += this.faqTree.faq[i].testo + ' --> ' + 'risposta: ' + risposta;
            if (typeof (this.faqTree.faq[i].SI.exit) != 'undefined' && this.faqTree.faq[i].opzioneUtente == 1) {
                console.log(this.faqTree.faq[i]);
                if (exit > this.faqTree.faq[i].SI.exit || exit == null)
                    exit = this.faqTree.faq[i].SI.exit;
                exitID = exit;
            }
            else if (typeof (this.faqTree.faq[i].NO.exit) != 'undefined' && this.faqTree.faq[i].opzioneUtente == 0) {
                console.log(this.faqTree.faq[i]);
                if (exit > this.faqTree.faq[i].NO.exit || exit == null)
                    exit = this.faqTree.faq[i].NO.exit;
                exitID = exit;
            }
            if (exit > 0)
                exit_message = this.faqTree.exit_message[exit];
            exitID = exit;
            if (i < this.faqTree.faq.length - 1) {
                // separator
                this.level1Faq += ' - ';
            }
        }
        console.log(' [FaqCase004Component ] + onRegistra() -->   this.level1Faq --> ', this.level1Faq);
        console.log(' [FaqCase004Component ] + onRegistra() -->   this.faqTree.note --> ', this.faqTree.note);
        console.log(' [FaqCase004Component ] + onRegistra() -->   this.faqTree.email --> ', this.faqTree.email);
        console.log(' [FaqCase004Component ] + onRegistra() -->   exit_message --> ', exit_message);
        console.log(' [FaqCase004Component ] + onRegistra() -->  p --> ', this.p);
        console.log(' [FaqCase004Component ] + onRegistra() -->   exit_code --> ', exitID);
        this.clearSessionStorage();
        this.router.navigate(['pin-page'], {
            queryParams: {
                a: this.a,
                b: this.b,
                level1Faq: this.level1Faq,
                note: this.faqTree.note != '' ? this.faqTree.note : '',
                exit: exit_message,
                paramExitID: exitID,
                communicationType: this.faqTree.email ? 'si' : 'no',
                p: this.p // pin
            },
            queryParamsHandling: 'merge'
        });
    };
    FaqCase004Component.prototype.onCloseAlert = function () {
        console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);
        this.errorStatus = null;
        this.errorMessage = null;
    };
    FaqCase004Component.prototype.clearSessionStorage = function () {
        if (sessionStorage.getItem('PinStorage')) {
            console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
            sessionStorage.removeItem('PinStorage');
        }
    };
    return FaqCase004Component;
}());
export { FaqCase004Component };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KpiLogEvent } from 'src/app/services/kpi-logs.service';
var ContractPageComponent = /** @class */ (function () {
    function ContractPageComponent(route, pinsService, router, location, datePipe, kpiLogsService) {
        this.route = route;
        this.pinsService = pinsService;
        this.router = router;
        this.location = location;
        this.datePipe = datePipe;
        this.kpiLogsService = kpiLogsService;
        this.isLoading = true;
        this.displayFull = true;
        //errorStatus: string = null;
        //errorMessage: string = null;
        //handleErrorMessage: string = null;
        //faExclamationCircle = faExclamationCircle;
        this.NOT_AVAILABLE = 'Informazione non disponibile al momento';
        this.today = new Date();
        this.activeDossier = null;
    }
    //  ET441MD
    //  AM034NK
    ContractPageComponent.prototype.ngOnDestroy = function () {
    };
    ContractPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.targa = p.targa;
                this.companyId = p.companyId;
                this.anomalyCode = p.anomalyCode;
                console.log(' + ngOnInit() --> this.targa --> ', this.targa);
                console.log(' + ngOnInit() --> this.companyId --> ', this.companyId);
                this.displayFull = this.companyId != null && this.companyId.length > 0;
                this.pinsService.getContractInfo(this.targa, this.companyId).subscribe(function (response) {
                    console.log('raw response --> ', response);
                    _this.contractInfo = response;
                    // fix data
                    _this.contractInfo.telematicContractSummary.status = Number(_this.contractInfo.telematicContractSummary.status);
                    if (_this.contractInfo.telematicContractSummary.contractStartDate)
                        _this.contractInfo.telematicContractSummary.contractStartDate = new Date(_this.contractInfo.telematicContractSummary.contractStartDate);
                    if (_this.contractInfo.telematicContractSummary.contractEndDate)
                        _this.contractInfo.telematicContractSummary.contractEndDate = new Date(_this.contractInfo.telematicContractSummary.contractEndDate);
                    /* TEST
                    this.contractInfo.telematicContractSummary.status = 0;
                    this.contractInfo.telematicContractSummary.contractStartDate = new Date();
                    this.contractInfo.telematicContractSummary.contractStartDate.setDate(this.contractInfo.telematicContractSummary.contractStartDate.getDate() - 2);
                    this.contractInfo.telematicContractSummary.contractEndDate = new Date();
                    this.contractInfo.telematicContractSummary.contractEndDate.setDate(this.contractInfo.telematicContractSummary.contractEndDate.getDate() + 3);
                    */
                    if (_this.contractInfo.telematicContractSummary.dossiers && _this.contractInfo.telematicContractSummary.dossiers.length > 0) {
                        _this.contractInfo.telematicContractSummary.dossiers.forEach(function (d) {
                            if (d.dossierOpenDate)
                                d.dossierOpenDate = new Date(d.dossierOpenDate);
                            if (d.dossierCloseDate)
                                d.dossierCloseDate = new Date(d.dossierCloseDate);
                        });
                    }
                    console.log('patched response --> ', response);
                    var data = {
                        vehiclePlate: _this.targa
                    };
                    if (_this.anomalyCode && _this.anomalyCode != '000')
                        data['notes'] = JSON.stringify({ anomalyCode: _this.anomalyCode });
                    _this.kpiLogsService.registerKPILogs(_this.displayFull ? KpiLogEvent.CTRL_PANEL_SMS : KpiLogEvent.CTRL_PANEL_GENERIC, data);
                    _this.isLoading = false;
                }, function (error) {
                    console.log(' [ContractPageComponent] + get info: error --> ', error);
                    _this.router.navigate(['error']);
                    _this.isLoading = false;
                });
                return [2 /*return*/];
            });
        }); });
    };
    ContractPageComponent.prototype.onBack = function () {
        this.location.back();
    };
    // DECODERS
    ContractPageComponent.prototype.getContractStaus = function () {
        if (this.contractInfo.telematicContractSummary.contractStartDate
            && this.contractInfo.telematicContractSummary.status == 0
            && this.contractInfo.telematicContractSummary.contractStartDate > this.today) {
            return "Il contratto non è ancora attivo: attendere";
        }
        if (this.contractInfo.telematicContractSummary.contractEndDate) {
            if (this.displayFull) {
                if (this.contractInfo.telematicContractSummary.status == 0) {
                    var stats = ['Attivo', 'Attesa rinnovo o chiusura'];
                    return this.contractInfo.telematicContractSummary.contractEndDate >= this.today ? stats[0] : stats[1];
                }
                else {
                    var stats = ['Chiuso', 'In chiusura', 'Il contratto non è ancora attivo: attendere', 'Sospeso'];
                    return stats[this.contractInfo.telematicContractSummary.status - 1];
                }
            }
            else {
                if (this.contractInfo.telematicContractSummary.status == 0) {
                    var stats = ['Regolare', 'Sospeso'];
                    return this.contractInfo.telematicContractSummary.contractEndDate >= this.today ? stats[0] : stats[1];
                }
                else {
                    var stats = ['Chiuso', 'Da verificare', 'Il contratto non è ancora attivo: attendere', 'Sospeso'];
                    return stats[this.contractInfo.telematicContractSummary.status - 1];
                }
            }
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getContractStartDate = function () {
        if (this.contractInfo.telematicContractSummary.contractStartDate && this.contractInfo.telematicContractSummary.contractEndDate) {
            if (this.displayFull) {
                if (this.contractInfo.telematicContractSummary.status == 4)
                    return "Contratto Sospeso";
                else if (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
                    return "Attesa rinnovo o chiusura";
                else {
                    var ret = this.datePipe.transform(this.contractInfo.telematicContractSummary.contractStartDate, "dd/MM/yyyy");
                    if (this.contractInfo.telematicContractSummary.contractStartDate > this.today)
                        ret += " - Inizio decorrenza futura";
                    return ret;
                }
            }
            else {
                //none
            }
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getContractEndDate = function () {
        if (this.contractInfo.telematicContractSummary.contractEndDate) {
            if (this.displayFull) {
                if (this.contractInfo.telematicContractSummary.status == 4)
                    return "Contratto Sospeso";
                else if (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
                    return "Attesa rinnovo o chiusura";
                else {
                    var ret = this.datePipe.transform(this.contractInfo.telematicContractSummary.contractEndDate, "dd/MM/yyyy");
                    if (this.contractInfo.telematicContractSummary.contractEndDate < this.today)
                        ret += " - Validità terminata";
                    return ret;
                }
            }
            else {
                //none
            }
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getContractValidity = function () {
        if (this.contractInfo.telematicContractSummary.contractStartDate && this.contractInfo.telematicContractSummary.contractEndDate) {
            if (this.displayFull) {
                // none
            }
            else {
                if (this.contractInfo.telematicContractSummary.status == 4)
                    return "Contratto Sospeso";
                if (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
                    return "Attesa rinnovo o chiusura";
                if (this.contractInfo.telematicContractSummary.contractStartDate > this.today)
                    return "Decorrenza validità futura";
                if (this.contractInfo.telematicContractSummary.contractEndDate < this.today)
                    return "Validità terminata";
                return "In corso di validità";
            }
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getSalesChannel = function () {
        if (this.contractInfo.telematicContractSummary.status == 3 /*||
          (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractStartDate > this.today)*/) {
            return "";
        }
        if (this.contractInfo.telematicContractSummary.salesChannel != null) {
            var chans = ['Agenzia', 'vs Assicuratore', 'vs Assicuratore', 'vs Assicuratore', 'Agenzia'];
            return chans[this.contractInfo.telematicContractSummary.salesChannel];
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getSalesAgency = function () {
        if (this.contractInfo.telematicContractSummary.agencyId != null) {
            return this.contractInfo.telematicContractSummary.agencyId;
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getInstaller = function () {
        if (this.contractInfo.installerInfo != null) {
            var desc = this.contractInfo.installerInfo.ragioneSociale;
            if (this.contractInfo.installerInfo.numeroTelefono)
                desc += " e numero di telefono: " + this.contractInfo.installerInfo.numeroTelefono;
            return desc;
        }
        return "Dato non disponibile";
    };
    /*
      getInstallerName(){
        if(this.contractInfo.installerInfo != null){
          return this.contractInfo.installerInfo.ragioneSociale;
        }
        return this.NOT_AVAILABLE;
      }
      getInstallerPhone(){
        if(this.contractInfo.installerInfo != null){
          return this.contractInfo.installerInfo.numeroTelefono;
        }
        return '';
      }
    */
    ContractPageComponent.prototype.getDeviceType = function () {
        if (this.contractInfo.telematicContractSummary.telematicDevice.deviceType != null) {
            var decoder = {
                F: "Per Auto con installazione in officina",
                T: "Per Auto con installazione in officina e servizi Top",
                M: "Per Moto con installazione in officina",
                E: "(E) non più disponibile",
                S: "Per Auto con installazione Assicurato",
                B: "(B) non più disponibile",
                V: "Per Auto con installazione in officina e accessorio voce",
                U: "Per Moto con installazione Assicurato",
                A: "Per Auto con installazione in officina e accessorio voce",
                C: "Per Auto con installazione Assicurato",
            };
            if (decoder[this.contractInfo.telematicContractSummary.telematicDevice.deviceType] != null)
                return decoder[this.contractInfo.telematicContractSummary.telematicDevice.deviceType];
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getIMEI = function () {
        if (this.contractInfo.telematicContractSummary.telematicDevice.imei != null) {
            if (!(new RegExp('^[0]*$').test(this.contractInfo.telematicContractSummary.telematicDevice.imei))) {
                return this.contractInfo.telematicContractSummary.telematicDevice.imei;
            }
        }
        return null;
    };
    ContractPageComponent.prototype.shouldDisplayAnomalySection = function () {
        var ret = false;
        var dossier = this.getActiveDossier();
        if (dossier != null && dossier.dossierCloseDate == null
            && (dossier.dossierType == 1 || dossier.dossierType == 2)
            && this.getDeviceStatus() != null
            && this.contractInfo.telematicContractSummary.status != 1)
            ret = true;
        if (this.contractInfo.telematicContractSummary.status == 0
            && this.contractInfo.telematicContractSummary.contractEndDate >= this.today
            && this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus != null) {
            if (this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus.toLowerCase() == 'active')
                if (!dossier || (dossier.dossierType != 1 && dossier.dossierType != 2))
                    ret = false;
        }
        return ret;
    };
    ContractPageComponent.prototype.getActiveDossier = function () {
        var e_1, _a;
        if (!this.activeDossier) {
            if (this.contractInfo.telematicContractSummary.dossiers && this.contractInfo.telematicContractSummary.dossiers.length) {
                try {
                    for (var _b = tslib_1.__values(this.contractInfo.telematicContractSummary.dossiers), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var d = _c.value;
                        if (d.dossierType != 0 && d.dossierOpenDate != null) {
                            if (d.dossierCloseDate == null) {
                                if (this.activeDossier == null || d.dossierType == 1 || d.dossierCloseDate != null)
                                    this.activeDossier = d;
                            }
                            else {
                                if (this.activeDossier == null)
                                    this.activeDossier = d;
                            }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        return this.activeDossier;
    };
    ContractPageComponent.prototype.getDeviceStatus = function () {
        if (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate >= this.today && this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus != null) {
            var dossier = this.getActiveDossier();
            switch (this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus.toLowerCase()) {
                case "active":
                    if (dossier && dossier.dossierCloseDate == null) {
                        if (dossier.dossierType == 1)
                            return "Rilevata possibile anomalia, in corso di verifica";
                        else if (dossier.dossierType == 2)
                            return "Analisi possibile anomalia in corso di verifica";
                    }
                    return "Funzionamento regolare";
                case "notactive":
                case "not active":
                    return "Non installato sul veicolo";
                case "notlinked":
                case "not linked":
                    return "Non ancora registrato";
                //case "theft": non gestisco, ha default message
                case "anomaly":
                    if (dossier && dossier.dossierCloseDate == null)
                        return "Analisi possibile anomalia in corso di verifica";
            }
        }
        return null;
    };
    ContractPageComponent.prototype.getAnomalyState = function () {
        var dossier = this.getActiveDossier();
        if (dossier && dossier.dossierCloseDate == null) {
            var decode = {
                1: "Anomalia in corso di verifica",
                2: "Anomalia in corso di analisi"
            };
            return decode[dossier.dossierType] || this.NOT_AVAILABLE;
        }
        return this.NOT_AVAILABLE;
    };
    ContractPageComponent.prototype.getAnomalyType = function () {
        //if(this.contractInfo.jiraDossierData){ 
        var dossier = this.getActiveDossier();
        if (dossier && dossier.dossierCloseDate == null) {
            var decode = {
                1: "1-Registrazione posizione",
                2: "2-Rilevazione spostamento",
                3: "3-Rilevazione spostamento",
                4: "4-Frequenza comunicazione",
                5: "5-Frequenza comunicazione",
                6: "6-Stato alimentazione",
                7: "7-Rilevamento accelerazione",
                8: "8-Rilevamento accelerazione",
                9: "SUEB".indexOf(this.contractInfo.telematicContractSummary.telematicDevice.deviceType) >= 0 ? "9a-Installazione non rilevata" : "9b-Installazione non completata",
                10: "10-Generica",
                11: "11-Anomalia tecnica",
                12: "12-Anomalia tecnica",
                13: "13-Rilevazione posizione",
                14: "14-Anomalia tecnica",
                16: this.displayFull ? "16-Plancetta solar NON comunicante" : "16- Anomalia Tecnica",
                17: this.displayFull ? "17-Plancetta Solar NON attivata" : "17-Anomalia Tecnica",
                20: this.displayFull ? "20-Anomalia di migrazione" : "20-Anomalia Tecnica",
                25: this.displayFull ? "25-Evento registrato da installatore" : "25-Anomalia in Verifica veicolo in officina",
                26: this.displayFull ? "26-Evento registrato da Ops Alfa" : "26-Anomalia in Verifica"
            };
            //const idx = Number(this.contractInfo.jiraDossierData.dossierAnomalyCode);
            var idx = Number(dossier.anomalyId);
            if (!isNaN(idx) && decode[idx])
                return decode[idx];
        }
        return this.NOT_AVAILABLE;
    };
    return ContractPageComponent;
}());
export { ContractPageComponent };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FaqCaseComponent } from 'src/app/core/faq-case-component';
var FaqCase007Component = /** @class */ (function (_super) {
    tslib_1.__extends(FaqCase007Component, _super);
    function FaqCase007Component(route, router, faqService, pinsService, errorsService) {
        return _super.call(this, route, router, faqService, pinsService, errorsService) || this;
    }
    FaqCase007Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    return FaqCase007Component;
}(FaqCaseComponent));
export { FaqCase007Component };

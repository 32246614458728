import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
var SmsPageCase007Component = /** @class */ (function (_super) {
    tslib_1.__extends(SmsPageCase007Component, _super);
    function SmsPageCase007Component(route, router) {
        return _super.call(this, route, router, 'faq-case007') || this;
    }
    SmsPageCase007Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    SmsPageCase007Component.prototype.onPinEvent = function (pin) {
        console.log("onPinEvent [" + this.name + "]");
        _super.prototype.onPinEvent.call(this, pin);
    };
    return SmsPageCase007Component;
}(SmsPageCaseComponent));
export { SmsPageCase007Component };

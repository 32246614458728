import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
var Level2FaqComponent = /** @class */ (function () {
    function Level2FaqComponent(route, faqService, router, location, pinsService) {
        this.route = route;
        this.faqService = faqService;
        this.router = router;
        this.location = location;
        this.pinsService = pinsService;
        this.faqTree = null;
        this.a = null;
        this.d = null;
        this.p = null;
        this.level1Faq = null;
        this.level2Faq = null;
        this.faqs = null;
        this.note = null;
        this.email = null;
        this.pin = null;
        this.faArrowLeft = faArrowLeft;
        this.faCheckSquare = faCheckSquare;
        this.faSquare = faSquare;
    }
    Level2FaqComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (p) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var e_1, _a, _b, err_1, _c, err_2, _d, _e, x;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        console.log(' [Level2FaqComponent] + p --> ', p);
                        this.a = p.a;
                        this.d = p.d;
                        this.p = p.p;
                        this.level1Faq = p.level1Faq;
                        console.log(' [Level2FaqComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
                        console.log(' [Level2FaqComponent] + ngOnInit() --> this.d (problemCode)--> ', this.d);
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        _b = this;
                        return [4 /*yield*/, this.pinsService.pinData(this.a, this.p, 'G')];
                    case 2:
                        _b.pin = _f.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _f.sent();
                        console.log(err_1);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 4:
                        if (!this.level1Faq) return [3 /*break*/, 9];
                        _f.label = 5;
                    case 5:
                        _f.trys.push([5, 7, , 8]);
                        _c = this;
                        return [4 /*yield*/, this.faqService.getFaqFlussoGenerico(this.p, this.a).toPromise()];
                    case 6:
                        _c.faqTree = (_f.sent());
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _f.sent();
                        console.log(err_2);
                        return [2 /*return*/, this.router.navigate(['error'])];
                    case 8:
                        try {
                            // this.faqTree.forEach(faqElement => {
                            //   faqElement.isVisible = false;
                            // });
                            for (_d = tslib_1.__values(this.faqTree), _e = _d.next(); !_e.done; _e = _d.next()) {
                                x = _e.value;
                                if (x.id == this.level1Faq) {
                                    this.faqs = x;
                                    this.faqTitle = x.name;
                                    console.log("Value of x: ", x);
                                    console.log("Value of x.name: ", x.name);
                                    this.faqs.faqLivello2.forEach(function (faqElement) {
                                        console.log("Value of faqElement: ", faqElement);
                                        faqElement.isVisible = false;
                                    });
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        console.log(' [Level2FaqComponent] + ngOnInit() --> this.leve1Faq -->', this.level1Faq);
                        console.log(' [Level2FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
                        console.log(' [Level2FaqComponent] + ngOnInit() --> level2Faq -->', this.level2Faq);
                        console.log(' [Level2FaqComponent] + ngOnInit() --> this.pin.hasEmail -->', this.pin.hasEmail);
                        _f.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        }); }, function (err) {
            console.log('Error message --->', err);
            _this.router.navigate(['error']);
        });
    };
    Level2FaqComponent.prototype.goBack = function () {
        this.location.back();
    };
    Level2FaqComponent.prototype.onClickLevel2Faq = function (faq) {
        console.log(' + onClickLevel2Faq() --> faq -->', faq);
        this.level2Faq = !faq.isVisible ? faq.codiceFaq : null;
        console.log(' + onClickLevel2Faq() --> this.level2Faq -->', this.level2Faq);
        this.faqs.faqLivello2.forEach(function (faqElement) {
            if (faqElement.name === faq.name) {
                faqElement.isVisible = !faqElement.isVisible;
            }
            else {
                faqElement.isVisible = false;
            }
        });
    };
    Level2FaqComponent.prototype.onProcedi = function (event) {
        event.preventDefault();
        console.log(' + onProcedi() --> this.level1Faq --> ', this.level1Faq);
        console.log(' + onProcedi() --> this.level2Faq --> ', this.level2Faq);
        console.log(' + onProcedi() --> note --> ', this.note);
        console.log(' + onProcedi() --> email --> ', this.email);
        this.clearSessionStorage();
        this.router.navigate(['pin-page'], {
            queryParams: {
                level2Faq: this.level2Faq,
                note: this.note,
                communicationType: this.email !== null && this.email !== undefined ? this.email ? 'si' : 'no' : 'no'
            },
            queryParamsHandling: 'merge'
        });
    };
    Level2FaqComponent.prototype.clearSessionStorage = function () {
        if (sessionStorage.getItem('PinStorage')) {
            console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
            sessionStorage.removeItem('PinStorage');
        }
    };
    return Level2FaqComponent;
}());
export { Level2FaqComponent };
